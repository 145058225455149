import React from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'

import {PageAssetConfig} from './modules/asset-config'
import {PageAssetsSettings} from './modules/assets/components'
import {DefaultAssetIdRedirect} from './modules/assets/components/DefaultAssetIdRedirect'
import {DefaultAssetTypeRedirect} from './modules/assets/components/DefaultAssetTypeRedirect'
import {NewBaseLoadGuard} from './modules/assets/components/NewBaseLoadGuard'
import {PageAsset} from './modules/assets/components/PageAsset'
import {PageAssetListPerType} from './modules/assets/components/PageAssetListPerType'
import {PageNewAsset} from './modules/assets/components/PageNewAsset'
import {useFeatureFlag} from './modules/common/hooks/useFeatureFlag'
import {PageJanusConfig} from './modules/janus'
import {SelectedView} from './modules/janus/enums'
import {KpiCalculationSettings} from './modules/kpi-calculation/components'
import {PageMaterialSettings} from './modules/materials/components'
import {PageMeasurementTypesSettings} from './modules/measurements'
import {PLANT_ROUTES, ROUTES, ROUTES_PERMISSIONS, getRelPath, getUrl} from './modules/routing'
import {PermissionGuardedRoute} from './modules/routing/components'
import {PageSamplingPointsSettings} from './modules/sampling-points'
import {PageStorageSettings} from './modules/storages/components'
import {PageUpmSettings} from './modules/upm/components'

export const GuardedRoutes: React.FC = () => {
  const isKpiCalculationsTabEnabled = useFeatureFlag('kpiCalculationsTab')

  return (
    <Routes>
      <Route
        path=""
        element={<PermissionGuardedRoute requiredPermissions={['VIEW_MATERIAL_STORAGE']} />}
      >
        <Route path={getRelPath(PLANT_ROUTES.STORAGES.path)} element={<PageStorageSettings />} />
      </Route>
      <Route
        path=""
        element={
          <PermissionGuardedRoute requiredPermissions={['CREATE_MASTER_DATA', 'VIEW_UPM']} />
        }
      >
        <Route path={getRelPath(PLANT_ROUTES.ASSET_CONFIG.path)} element={<PageAssetConfig />} />
      </Route>
      <Route path="" element={<PermissionGuardedRoute requiredPermissions={['VIEW_UPM']} />}>
        <Route path={PLANT_ROUTES.JANUS_CONFIG.relPath}>
          <Route
            element={<PageJanusConfig selectedView={SelectedView.plantSetup} />}
            index={true}
          />
          <Route
            path={PLANT_ROUTES.PLANT_SETUP.relPath}
            element={<PageJanusConfig selectedView={SelectedView.plantSetup} />}
          />
          <Route
            path={PLANT_ROUTES.STANDARD_REPORTS.relPath}
            element={<PageJanusConfig selectedView={SelectedView.standardReports} />}
          />
          <Route
            path={PLANT_ROUTES.ENTRY_DAILY.relPath}
            element={<PageJanusConfig selectedView={SelectedView.dailyEntry} />}
          />
          <Route
            path={PLANT_ROUTES.ENTRY_MONTHLY.relPath}
            element={<PageJanusConfig selectedView={SelectedView.monthlyEntry} />}
          />
        </Route>
      </Route>
      <Route path="" element={<PermissionGuardedRoute requiredPermissions={['VIEW_ASSETS']} />}>
        <Route path={getRelPath(PLANT_ROUTES.ASSETS.path)} element={<PageAssetsSettings />}>
          <Route index element={<DefaultAssetTypeRedirect />} />
          <Route
            path={getRelPath(PLANT_ROUTES.ASSETS.ASSET_TYPE.path)}
            element={<PageAssetListPerType />}
          >
            <Route index element={<DefaultAssetIdRedirect />} />
            <Route path={getRelPath(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.path)}>
              <Route index element={<DefaultAssetIdRedirect />} />
              <Route
                path={getRelPath(PLANT_ROUTES.ASSETS.ASSET_TYPE.ID.ASSET_ID.path)}
                element={<PageAsset />}
              />
            </Route>
            <Route
              path=""
              element={<PermissionGuardedRoute requiredPermissions={['CHANGE_ASSETS']} />}
            >
              <Route path="" element={<NewBaseLoadGuard />}>
                <Route
                  path={getRelPath(PLANT_ROUTES.ASSETS.ASSET_TYPE.NEW_ASSET.path)}
                  element={<PageNewAsset />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="" element={<PermissionGuardedRoute requiredPermissions={['VIEW_MATERIALS']} />}>
        <Route path={getRelPath(PLANT_ROUTES.MATERIALS.path)} element={<PageMaterialSettings />} />
      </Route>
      <Route path="" element={<PermissionGuardedRoute requiredPermissions={['VIEW_UPM']} />}>
        <Route path={getRelPath(PLANT_ROUTES.UPM.path)} element={<PageUpmSettings />} />
      </Route>
      <Route
        path=""
        element={
          <PermissionGuardedRoute
            requiredPermissions={[ROUTES_PERMISSIONS.MEASUREMENTS]}
            isRouteAllowed={useFeatureFlag('measurementTypestMapping')}
          />
        }
      >
        <Route
          path={getRelPath(PLANT_ROUTES.MEASUREMENTS.path)}
          element={<PageMeasurementTypesSettings />}
        />
      </Route>
      <Route
        path=""
        element={
          <PermissionGuardedRoute
            requiredPermissions={[ROUTES_PERMISSIONS.SAMPLING_POINTS]}
            isRouteAllowed={useFeatureFlag('samplingPointMapping')}
          />
        }
      >
        <Route
          path={getRelPath(PLANT_ROUTES.SAMPLING_POINTS.path)}
          element={<PageSamplingPointsSettings />}
        />
      </Route>
      {/* TODO: update the permission when new permission is created on BE
          VIEW_MATERIAL_STORAGE and CHANGE_MATERIAL_STORAGE */}
      <Route
        path=""
        element={
          <PermissionGuardedRoute
            requiredPermissions={['VIEW_MATERIAL_STORAGE']}
            isRouteAllowed={isKpiCalculationsTabEnabled}
          />
        }
      >
        <Route
          path={getRelPath(PLANT_ROUTES.KPI_CALCULATION.path)}
          element={<KpiCalculationSettings />}
        />
      </Route>
      <Route path="*" element={<Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />} />
    </Routes>
  )
}
